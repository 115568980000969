.last-code-submission-modal {
	.modal-title {
		font-weight: normal;
		font-size: 16px;
		color: #333;
	}

	.modal-header {
		button {
	    	position: relative;
    		top: -1px;

    		&:focus,
    		&:active {
    			border: none;
    		}
    	}

		.close {
			opacity: 0.6;
		}
	}

	.modal-footer {
		color: #777;
	}

	.CodeMirror-activeline-background {
    	background: transparent !important;
	}

	.CodeMirror-focused .CodeMirror-activeline-background {
    	background: rgba(100, 100, 100, 0.1) !important;
	}

	.CodeMirror-activeline-gutter {
		background: transparent !important;
	}
}