.about-container {
	height: 100%;

	.about-main-container {
		width: 800px;
	}

	.user-image {
		float: left;
	}

	@media only screen and (max-width: 850px) {
		.about-main-container {
			width: 600px;
		}
	}

	@media only screen and (max-width: 650px) {
  		.user-image {
    		float: none;
    		margin: 0 auto;
  		}

		.about-main-container {
			width: 100%;
		}

		.title-container {
			h1 {
				text-align: center;
			}

			h2 {
				font-size: 22px !important;
				text-align: center;
			}
		}
	}
}
