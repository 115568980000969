@import-normalize;

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

    #root {
        position: relative;
        height: 100%;
        overflow: hidden;

        #app-container {
            height: 100%;
        }
    }
}

.with-font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main-container {
    position: relative;
    top: -60px;
    width: 100%;
    height: 100%;
    padding-top: 60px;

    .main-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 60px;
        overflow: auto;
    }
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-right-10 {
    margin-right: 10px;
}

.float-right {
    float: right;
}

.result {
    text-align: center;
    margin-top: 30px;
    float: right;
    width: 20%;
    height: 500px;
}

span.list-group-item {
    text-align: center;
}

.list-group-item  {
    strong:first-child {
        margin-right: 10px;
    }
    strong:not(:first-child) {
        margin-right: 10px;
        margin-left: 10px;
    }
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
	line-height: 1.6;
    margin-bottom: 0;
}

.show {
    display: block;
}

.hide {
    display: none;
}

/* loader and spinner */
.spinner-container {
    margin: 100px auto 0;
    width: 110px;
    text-align: center;
}
.spinner > div {
  width: 18px;
  height: 18px;
  margin: 0 3px;
  background-color: #067191;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 0.9s infinite ease-in-out both;
  animation: sk-bouncedelay 0.9s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loading-gif {
    height: 132px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.loadingText {
    text-align: center;
}

@keyframes rotateLoading {
    0%  { transform: rotate(0deg); -webkit-transform: rotate(0deg); }
    100% { transform: rotate(360deg); -webkit-transform: rotate(360deg); }
}

@-webkit-keyframes rotateLoading {
    0%  { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@-moz-keyframes rotateLoading {
    0%  { -moz-transform: rotate(0deg); }
    100% { -moz-transform: rotate(360deg); }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.4) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}