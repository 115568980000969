.dashboard-container {
    height: 100%;
    margin: 0;
    margin-left: 256px;
    padding: 20px;
    overflow: auto;

    .alert-container {
    	font-size: 17px;
    	// border-color: #17a2b8;
        border-color: #ffc107;
        text-align: center;
    }

    .core-foundation-stat-container,
    .full-course-stat-container {
        width: 400px;
    	margin-top: 15px;
        margin-bottom: 50px;
    	max-width: 800px;

    	h3 {
    		text-align: center;
    		margin-bottom: 20px;
    	}
    }

    .core-foundation-stat-container {
    	h3 {
			width: 250px;
			margin: 0 auto 20px;
    	}
    }
}
