$backgroundColor: #f4f5f7;

#landing-page-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    font-style: normal;

    .main-content {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .headerContainer {
            height: 70px;

            .brand-container {
                width: 100%;
                height: 100%;
                float: none;

                .logo-container {
                    float: left;
                    position: relative;
                    height: 100%;

                    .logo {
                        background-position-y: 16px;
                    }

                    .name {
                        padding-left: 55px;

                        h1 {
                            line-height: 70px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .right-panel {
            position: relative;
            height: 100%;
            float: right;

            .sign-up-container,
            .log-in-container,
            .premium-container {
                position: relative;
                height: 100%;
                padding: 0 12px;
                float: right;
                line-height: 68px;
                border-bottom: none;

                &:hover:after {
                    transform: scaleX(1);
                    transition: transform 250ms ease-in-out;
                }

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #0052CC;
                    font-weight: 500;
                    text-decoration: none;
                    font-size: 17px;

                    &:hover {
                        color: #0065FF;
                    }
                }

                &:after {
                    display:block;
                    content: '';
                    position:absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    border-bottom: solid 2px #0065FF;
                    transform: scaleX(0);  
                    transition: transform 50ms ease-in-out;
                }
            }
        }

        .curve-border-container {
            width: 100%;
            background: $backgroundColor;

            path {
                fill: white;
            }
        }

        .home {
            position: relative;
            background: $backgroundColor;
            padding-top: 15px;
            padding-bottom: 15px;

            .row .col {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .home-heading-container {
                margin-bottom: 25px;
            }

            .home-content {
                display: block;
                position: relative;
                color: #FFF;
                background: $backgroundColor;
                color: #253858;

                .brand-heading {
                    font-size: 39px;
                    line-height: 1.2;
                    margin-bottom: 16px;
                    font-family: 'Lato', sans-serif;
                }

                .brand-subtitle {
                    font-size: 20px;
                    color: #42526E;
                    letter-spacing: .3px;
                    line-height: 1.4;
                    font-weight: 400px;
                    font-family: sans-serif;
                }

                .get-it-free-container {
                    position: relative;
                    width: 110px;
                    height: 40px;
                    background: #0052CD;
                    border: #0052CD;
                    margin: 0 auto;
                    border-radius: 3px;

                    a {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        line-height: 38px;
                        font-size: 16px;
                        color: white;
                        font-weight: 500;
                        text-decoration: none;
                        word-spacing: 1px;
                        padding-left: 15px;
                        padding-right: 15px;
                    }

                    &:hover {
                        background-color: #0037b3
                    }
                }

                .app-image-container {
                    position: relative;
                    width: 85%;
                    max-width: 950px;
                    max-height: 380px;
                    margin: 30px auto;
                    border: 1px solid #e1e4e9;
                    overflow: hidden;
                    border-radius: 7px;
                    -webkit-box-shadow: 0px 6px 7px 2px rgba(214,218,224,1);
                    -moz-box-shadow: 0px 6px 7px 2px rgba(214,218,224,1);
                    box-shadow: 0px 6px 7px 2px rgba(214,218,224,1);
                    
                    img {
                        width: 100%;
                    }

                }
            }
        }

        .feature-container {
            padding-top: 60px;
            padding-bottom: 120px;

            .algorithm,
            .people-coding,
            .core-foundation {
                padding-left: 17px;
                padding-right: 17px;
                min-width: 250px;

                .feature-img-container {
                    min-height: 200px;

                    img {
                        background: transparent;
                    }
                }

                .description {
                    margin-top: 17px;
                }
            }

            .algorithm {
                img {
                    width: 150px;
                }
            }

            .core-foundation {
                img {
                    margin: 15px 0;
                    width: 190px;
                }
            }

            .people-coding {
                img {
                    width: 205px;
                }
            }

        }

        .mission-container {
            .row {
                background: $backgroundColor;
            }

            .sf-icon-container {
                position: relative;
                margin: 0 auto;
                width: 130px;
                top: -36px;

                img {
                    width: 100%;
                }
            }

            .passion-container {
                text-align: center;
                margin: -15px 0 20px;
                font-size: 21px;
                color: #253858;
                font-family: 'Lato', sans-serif;

                span {
                    position: relative;
                    top: -2px;
                    margin: 0 6px;
                    font-size: 20px;
                    color: #d43b66;
                }
            }

            .mission-statement {
                max-width: 800px;
                margin: 0 auto;
                text-align: center;

                p {
                    margin-bottom: 5px;
                }
            }

            .company-logo-container {
                margin: 0 auto;

                img {
                    position: relative;
                    width: 70px;
                    margin: 0 10px;

                    &.amazon {
                        width: 80px;
                        top: 4px;
                    }

                    &.apple {
                        width: 25px;
                        top: -3px;
                    }

                    &.facebook {
                        width: 77px;
                        top: -1px;
                        margin-left: 15px;
                    }

                    &.walmart {
                        width: 90px;
                        top: 1px;
                    }

                    &.cisco {
                        width: 60px;
                        top: -3px;
                    }

                    &.hp {
                        width: 45px;
                    }

                    &.adp {
                        width: 50px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    &.idg {
                        width: 95px;
                        top: -4px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }
            }

            .privacyAndTermContainer {
                width: 100%;
                margin: 30px auto 0;
                border-top: none;
                padding: 0;
                text-align: center;

                a {
                    margin-left: 3px;
                }
            }

            @media only screen and (max-width: 1350px) {
                .privacyAndTermContainer {
                    margin-top: 50px;
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .main-content .headerContainer {
            padding-left: 0;
            padding-right: 0;

            .brand-container {
                margin-left: 0;
                margin-right: 0;

                .logo-container .name h1 {
                    font-size: 16px;
                }

                .right-panel {
                    .sign-up-container,
                    .log-in-container,
                    .premium-container {
                        padding: 0 4px;
                        line-height: 71px;

                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}