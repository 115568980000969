.term-container {
    height: 100%;

    .term-main-container {
        width: 800px;
    }

    @media only screen and (max-width: 850px) {
        .term-main-container {
            width: 600px;
        }
    }

    @media only screen and (max-width: 650px) {
        .term-main-container {
            width: 100%;
        }

        .title-container {
            h1 {
                text-align: center;
            }

            h2 {
                font-size: 22px !important;
                text-align: center;
            }
        }
    }
}
