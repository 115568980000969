.product-container {
	.action-title {
		padding-bottom: 5px;
		margin: 30px auto 10px;
		font-size: 28px;
		color: #3d464d;
		font-weight: 300;
		font-family: AtlasGrotesk,sans-serif;
	}

	.product-main-content {
		width: 720px;
		margin: 0 auto;

		p {
			margin-bottom: 0;
		}

		.product-plan-container {
			padding: 30px;
			background-color: #fafafa;
    		border-radius: 8px;
    		margin: 0 0 20px;
    		border: 1px solid rgba(123,137,148,0.3);
		}

		.product-plan {
			width: 640px;
			margin: 30px auto 30px;

			.pricing-plan-container {
				position: relative;
				display: inline-block;
				width: 280px;
				margin: 10px 20px;
				vertical-align: top;
				background-color: white;

				&.most-popular::before {
					content: 'Most popular';
					display: block;
					width: 100%;
					left: 0;
					font-weight: 700;
					position: absolute;
					top: -35px;
					text-align: center;
					font-size: 16px;
					color: #0070e0;
					letter-spacing: 1px;
				}

				li {
					border: none;
				}

				&.coaching-container {
					& .pricing-panel {
						cursor: default;
						background-color: #f5f5f5;

						input {
							cursor: default;
						}
					}
				}

				.pricing-panel {
					cursor: pointer;
					border: 1px solid #e9ecef;
					border-radius: 5px;
					box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);

					.panel-heading {
						padding: 10px 15px;
	    				border-bottom: 1px solid transparent;
	    				border-top-left-radius: 3px;
	    				border-top-right-radius: 3px;
    				}

    				.panel-body {
    					padding: 15px;
    				}

    				.panel-footer {
    					padding: 10px 15px;
						background-color: #f5f5f5;
						border-top: 1px solid #ddd;
						border-bottom-right-radius: 3px;
						border-bottom-left-radius: 3px;
    				}

					.plan-title-container {
						height: 110px;
					}

					&.active {
						border: 1px solid #99c6f3;
						-webkit-box-shadow: 0px 0px 5px 3px #99c6f3;
						-moz-box-shadow: 0px 0px 5px 3px #99c6f3;
						box-shadow: 0px 0px 5px 3px #99c6f3;
					}

					input {
						vertical-align: top;
					}

					.select-button-container {
						background-color: transparent;
						border: none;
						padding-bottom: 30px;
						padding-top: 0;
					}
				}

				.plan-title {
					font-size: 20px;
					font-weight: 700;
					margin: 10px 0;
				}

				.plan-description {
					font-size: 15px;
					color: #444;
				}

				.main-feature-description {
					display: inline-block;
					height: 49px;
					color: #444;
				}

				.price-tag-container {
					margin: 15px 0;

					&.coaching {
						margin-top: 2px;
					}

					.price-tag {
						font-size: 28px;
						font-weight: 500;
					}

					.price-tag-cross {
						line-height: 16px;
						font-size: 23px;
						font-weight: 300;
						text-decoration: line-through;
						margin-bottom: 0;
						color: #444;
					}
				}
			}
		}

		.pay-button {
		    border-radius: 24px;

			button {
				width: 280px;
    			height: 42px;
				margin: 0 auto;
    			background-color: #5cb95c;
    			font-weight: 700;
    			font-size: 17px;
    			border-color: #52b95c;

    			svg {
    				margin-right: 10px;
    			}

    			&:hover {
					box-shadow: -1px 1px 10px 3px rgba(219,219,219,1);
					border-color: #52b95c;
    			}

    			&:focus {
    				box-shadow: none;

    				&:hover {
    					box-shadow: -1px 1px 10px 3px rgba(219,219,219,1);
    				}
    			}

    			&:focus,
    			&:active {
    				background-color: #5cb95c;
    				border-color: transparent;
    			}

    			&:active:focus {
    				border-color: #59e066;
    				box-shadow: none;
    			}
			}
		}

		.stripe-form-container {
			display: none;
			margin: 25px 0;
			width: 100%;

			.payment-form-container {
				width: 600px;
				margin: 0 auto;
				background-color: white;
			}

			.product-description {
				position: relative;
				height: 55px;
				font-size: 18px;
				color: #444;
				font-weight: 700;
				letter-spacing: 0.4px;

				.price {
					position: absolute;
					right: 0;
				}
			}

			form {
				width: 100%;
  				align-self: center;
				box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
					0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
				border-radius: 7px;
				padding: 40px;
				margin: 0 auto;
			}

			input {
				border-radius: 5px;
				margin-bottom: 15px;
				padding: 12px;
				border: 1px solid rgba(50, 50, 93, 0.1);
				max-height: 44px;
				font-size: 16px;
				width: 100%;
				background: white;
				box-sizing: border-box;
			}

			.result-message {
				width: 600px;
    			font-size: 18px;
    			margin: 0 auto;
    			text-align: center;
    			border: 2px solid #5cb95b;
    			padding: 10px;
    			border-radius: 5px;
    			line-height: 25px;

    			a {
    				font-weight: 600;
    				color: #088624;
    			}
			}

			.hidden {
				display: none;
			}

			.card-error {
				color: rgb(105, 115, 134);
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 15px;
				text-align: center;
				color: #dc3545;
			}

			#card-element {
				border-radius: 5px;
				padding: 12px;
				border: 1px solid rgba(50, 50, 93, 0.1);
				max-height: 44px;
				width: 100%;
				background: white;
				box-sizing: border-box;
				margin-bottom: 15px;
			}

			#payment-request-button {
				margin-bottom: 32px;
			}

			/* Buttons and links */
			button {
				background: #5cb95c;
				font-family: Arial, sans-serif;
				color: #ffffff;
				border-radius: 5px;
				border: 0;
				padding: 12px 16px;
				font-size: 16px;
				font-weight: 600;
				cursor: pointer;
				display: block;
				transition: all 0.2s ease;
				box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
				width: 100%;
			}

			button:hover {
			  	filter: contrast(115%);
			}

			button:disabled {
				  opacity: 0.7;
				  cursor: default;
			}

			/* spinner/processing state, errors */
			.spinner,
			.spinner:before,
			.spinner:after {
				border-radius: 50%;
			}

			.spinner {
				color: #ffffff;
				font-size: 22px;
				text-indent: -99999px;
				margin: 0px auto;
				position: relative;
				width: 20px;
				height: 20px;
				box-shadow: inset 0 0 0 2px;
				-webkit-transform: translateZ(0);
				-ms-transform: translateZ(0);
				transform: translateZ(0);
			}

			.spinner:before,
			.spinner:after {
			  	position: absolute;
			  	content: "";
			}

			.spinner:before {
				width: 10.4px;
				height: 20.4px;
				background: #5cb95c;
				border-radius: 20.4px 0 0 20.4px;
				top: -0.2px;
				left: -0.2px;
				-webkit-transform-origin: 10.4px 10.2px;
				transform-origin: 10.4px 10.2px;
				-webkit-animation: loading 2s infinite ease 1.5s;
				animation: loading 2s infinite ease 1.5s;
			}

			.spinner:after {
				width: 10.4px;
				height: 10.2px;
				background: #5cb95c;
				border-radius: 0 10.2px 10.2px 0;
				top: -0.1px;
				left: 10.2px;
				-webkit-transform-origin: 0px 10.2px;
				transform-origin: 0px 10.2px;
				-webkit-animation: loading 2s infinite ease;
				animation: loading 2s infinite ease;
			}

			@keyframes loading {
				0% {
					-webkit-transform: rotate(0deg);
					transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
					transform: rotate(360deg);
				}
			}

			@media only screen and (max-width: 600px) {
			}
		}

		.features-container {
			margin: 40px 20px 50px;

			.include-title {
				padding-bottom: 5px;
				margin: 30px 30px 10px;
				font-size: 28px;
				color: #3d464d;
				font-weight: 300;
				font-family: AtlasGrotesk,sans-serif;
			}

			.list-group {
			    margin: 0 auto;
			    padding: 0 20px;
				margin-bottom: 20px;

				.list-group-item {
					position: relative;
					float: left;
					width: 320px;
					border: none;
					padding: 0 30px;
				}

				.check-icon {
					position: absolute;
					margin-right: 8px;
				}

				.description {
					padding-left: 33px;
				}
			}
		}
	}

	@media only screen and (max-width: 850px) {
		.product-main-content {
			width: 700px;
		}
	}

	@media only screen and (max-width: 710px) {
		.product-main-content {
			width: 600px;

			.stripe-form-container {
				.result-message {
					width: 100%;
				}

				.payment-form-container {
					width: 100%;
				}
			}

			.product-plan {
				width: 100%;

				.pricing-plan-container {
					display: block;
					width: 100%;
					margin: 0 auto 60px;
    			}
			}

			.features-container .list-group .list-group-item:first-child {
				margin-bottom: 20px;
			}
		}
	}

	@media only screen and (max-width: 610px) {
		.product-main-content {
			width: 400px;

			.product-plan .pricing-plan-container {
				width: 100%;
			}
		}

		.product-main-content .features-container {
			margin-left: 0;
			margin-right: 0;

			.list-group {
			    margin: 0 auto;
			    padding: 0;
				margin-bottom: 20px;

				.list-group-item {
					width: 320px;
					padding: 0 10px;
				}

				.description {
					padding-left: 33px;
				}
			}
		}
	}
}
