$alert-red: #C22E2E;
$light-blue: #40b4de;

.brand-picture {
    height: 100%;

    .side-image {
        position: fixed;
        width: 43%;
        height: 100%;
        left: 0;
        background: url(https://s3-us-west-1.amazonaws.com/jscrunch/brand_picture.png) -510px center / cover no-repeat;
        opacity: 0;
        transition: all .5s ease-out;

        &.image-fader {
            min-width: 300px;
            opacity: 1;
            transition: all .5s ease-out;
        }
    }
}

.reset-password-container,
.reset-password-confirm-container,
.login-container,
.signup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    min-width: 750px;
    overflow: auto;

    .brand-container-wrapper {
        height: 60px;
        padding-left: 30%;
    }

    .reset-password-box,
    .reset-password-confirm-box,
    .login-box,
    .signup-box {
        position: absolute;
        top: 50%;
        height: 100%;
        left: 72%;
        padding: 80px 45px 55px;
        margin: 0 auto;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation: slidein .1s linear;
        animation: slidein .1s linear;

        .title {
            width: 420px;
            margin-bottom: 15px;
            margin-left: 1px;

            h1 {
                margin: 20px 0 10px;
                padding-bottom: 22px;
                color: #515a5f;
                font-size: 20px;
                text-align: center;
                font-weight: 700;
                letter-spacing: 0.77px;
                line-height: 28px;
            }
        }
    }

    .reset-password-box .title {
        width: 400px;

        h1 {
            padding-bottom: 0;
        }

        .reset-description {
            margin-top: 10px;
            padding-bottom: 6px;
            font-size: 14px;
        }
    }
}

.login-container,
.signup-container,
.reset-password-container,
.reset-password-confirm-container {
    .message-container {
        color: #2a8d2b;
        margin-bottom: 15px;
        font-size: 16px;
        text-align: center;

        &.error {
            color: #f10c07;
        }
    }
}

.login-container,
.signup-container,
.reset-password-container,
.reset-password-confirm-container {
    .auth {
        cursor: pointer;
        display: block;
    }

    .login-buttons {
        position: relative;
        width: 100%;
        margin: 0 auto;

        .btn-default {
            width: 100%;
            height: 50px;
            margin: 0 auto;
        }
    }

    .other-link {
        font-size: 14px;
        color: $light-blue;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .separator {
        margin: 10px 0;
        overflow: hidden;
        text-align: center;
        border-bottom: 1px solid #e4e4e4;
        margin: 20px 0 8px;

        .text-wrapper {
            position: relative;
            padding: 16px;

            &:before {
                content: '';
                position: absolute;
                border-bottom: 1px solid #e4e4e4;
                top: 50%;
                right: 100%;
                width: 5000px;
            }

            &:after {
                content: '';
                position: absolute;
                border-bottom: 1px solid #e4e4e4;
                top: 50%;
                left: 100%;
                width: 5000px;
            }

            .text {
                margin: 0px;
                padding-top: 0px;
                font-size: 15px;
                padding-bottom: 0px;
                font-weight: normal;
                word-wrap: break-word;
                line-height: 18px;
                letter-spacing: 0.2px;
                color: #767676;
            }
        }
    }

    .create_account_form {
        flex-display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        > h1 {
            display: relative;
            font-size: 34px;
            letter-spacing: .8px;
            font-weight: 200;
            line-height: 34px;
            margin: 0 0 5px 0;
        }

        .checkbox_group {
            display: relative;
            min-height: 40px;

            span {
               letter-spacing: .5px;
            }
        }

        #userFullName,
        #emailAddress,
        #password,
        #confirmPassword {
            font-size: 15px;
            border: 1px solid #d3d5d8;
            border-radius: 3px;

            &:focus,
            &:focus:hover {
                border-color: #00b9ff;
                -webkit-box-shadow: none;
                box-shadow: none;
            };

            &:hover {
                border-color: #829ca9;
            };
        }

        .full-name-message,
        .email-address-message,
        .password-message,
        .confirm-password-message {
            display: none;
            background-color: white;
            margin-top: 5px;
            padding: 3px 0;
            font-size: 14px;
        }

        .full-name-message,
        .email-address-message,
        .confirm-password-message {
            border: none;
            color: $alert-red;
        }

        .password-message {
            position: relative;
            background-color: #f2f5f7;
            margin: 0;
            padding: 4px 16px 3px;
            top: -2px;
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            transition: border-color .15s ease-in-out;
            color: #4a5860;
            border-color: #00b9ff;
            -webkit-box-shadow: none;
            box-shadow: none;

            &::before {
                content: " ";
                position: absolute;
                display: block;
                width: 10px;
                height: 10px;
                left: 20px;
                top: -5px;
                transform: rotate(45deg);
                background-color: inherit;
            }

            .is-valid {
                text-decoration: line-through;
            }
        }

        .password-group.has-warning {
            #password {
                border-color: #FFA600;
            }

            .password-message {
                border-color: #FFA600;
                background-color: #FFF3DD;
                color: #9E5F00;
            }
        }

        .password-group.secure-password {
            #password {
                border-color: #2ED06E;
            }

            .password-message {
                border-color: #2ED06E;
                background-color: #D6F5E2;
                color: #1B7940;
            }
        }

        .top-error-box {
            display: none;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #ffdfe0;
            border-color: #F53636;
            color: $alert-red;
            border-radius: 3px;
            border: none;
            font-size: 15px;
            font-weight: 400;

            .show {
                display: block;
            }

            .close {
                opacity: 1;
                color: $alert-red;
                font-size: 28px;
                line-height: 16px;
                font-weight: bolder;
            }
        }
    }

    form {
        width: 400px;
        margin-bottom: 60px;
    }
}

.margin-bottom-ten {
    margin-bottom: 10px;
}

.show {
    display: block !important;
}
