.error_container {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 100%;
  white-space: nowrap;
  line-height: 50px;
  width: auto;
  height: 50px;
  background: #F16767;
  box-sizing: border-box;
  padding: 0 20px 0 20px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .6px;
  user-select: none;
  transition: all, 0.2s, ease-in-out;

  &.visible {
    transform: translateX(0px);
    opacity: 1;
  }

  &.invisible {
    transform: translateX(-30px);
    opacity: 0;
    -webkit-animation-delay: 2s; /* Chrome, Safari, Opera */
    animation-delay: 2s;
  }
}