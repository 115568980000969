$dividerColor: #f0f0f0;
$codeMirrorSelected: rgb(181, 213, 255);
$font: 12px 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
$headersize: 50px;

.question-container {
    position: relative;
    height: 100%;
    margin: 0;
    margin-left: 256px;
    padding: 11px 20px;
    padding-left: 0;
    min-width: 1000px;

    .editor-container {
        width: 100%;
        height: 100%;
    }

    &.is-console-tab {
        .console-control button[name=showConsole] {
            color: #337ab7;
            font-weight: 500;
            background-color: white;
            /*border-bottom: 1px solid #337ab7;*/

            &:after {
                transform: scaleX(1);
            }
        }

        .console-and-test-container .test-container {
            display: none;
        }
    }

    &.is-test-tab {
        .console-control button[name=showTestResult] {
            color: #337ab7;
            font-weight: 500;
            background-color: white;
            /*border-bottom: 1px solid #337ab7;*/

            &:after {
                transform: scaleX(1);
            }
        }

        .console-and-test-container .console {
            display: none;
        }
    }

    .custom-page-header {
        width: 50%;
        margin: 0 auto;
        padding: 20px;
        text-align: center;
    }

    .split-pane-wrapper {
        position: relative;
        width: 100%;
        height: calc(100% - 50px);
        border-bottom: 1px solid #e5e5e5;

        .SplitPane.vertical {
            overflow: visible !important;
        }

        .SplitPane.horizontal {
            overflow: auto;
            border-right: 1px solid #e4e4e4;

            .Pane1 {
                border-right: none;
                border: 8px solid white;
            }

            .Pane2 {
                padding: 20px;
                overflow: auto;
            }
        }
    }

    h1 {
        margin: 0;
    }

    .question-header-container,
    .question-footer-container {
        width: 100%;
        background-color: #f7f7f7;
    }

    .question-header-container {
        position: relative;
        height: 50px;
        border: 1px solid rgb(228, 228, 228);
        border-left: none;

        .console-control {
            position: absolute; 
            top: 12px;  
            right: 11px;    
            width: 232px;   
            height: 38px;   
            background-color: white;    
            padding: 0 10px 0;  
            border-top: 1px solid rgb(228, 228, 228);   
            border-left: 1px solid rgb(228, 228, 228);  
            border-right: 1px solid rgb(228, 228, 228); 

            .divider {  
                float: right;   
                position: relative; 
                top: 9px;   
                width: 1px; 
                height: 21px;   
                margin: 0 10px; 
                background-color: #ddd; 
            }   

            button {    
				width: 89px;
				height: 27px;
				margin-top: 7px;
				padding: 1px 2px 4px 2px;
				border: none;
				background-color: white;
				border-radius: initial;
				font-size: 14px;
				line-height: normal;
				color: black;
                background-position: center;
                transition: background 0.5s;
                border-radius: 2px;

                &:hover,    
                &.focus {   
                    color: #337ab7; 
                    outline:none; 
                }   

                &:focus {
                    outline:none;
                    box-shadow: none;
                    background-color: white;
                }

                &:hover:after {
                    transform: scaleX(1);
                    transition: transform 250ms ease-in-out;
                }

                &:active {
                    background-color: #e9ecef;
                    background-size: 100%;
                    transition: background 0s;

                    &:focus {
                        border: none;
                        box-shadow: none;
                    }
                }

                &:after {
                    display:block;
                    content: '';
                    position: relative;
                    top: 6px;
                    width: 100%;
                    height: 2px;
                    border-bottom: solid 2px #337ab7;
                    transform: scaleX(0);  
                    transition: transform 50ms ease-in-out;
                }
            }   
        }

        .exercise-icon {
            width: 70px;
            height: 100%;
            display: inline-block;
            vertical-align:top;

            .pencil-outline {
                position: relative;
                top: 9px;
                left: 38px;

                svg {
                    width: 22px;
                    color: #555;
                }
            }
        }

        .title {
            display: inline-block;
            width: 180px;
            height: 100%;
            padding-left: 5px;
            line-height: 50px;
            font-size: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            vertical-align:top;
        }
    }

    .question-footer-container {
        height: 1px;
    }

    .control-container {
        display: inline-block;
        width: 430px;
        height: 100%;
        z-index: 100;

        .submit-button-wrapper {
            position: relative;
            float: right;
            right: 10px;
            font-size: 13px;
            margin-top: 9px;

            button {
                user-select: none;
                display: inline-flex;
                height: 30px;
                min-width: 65px;
                border: 1px solid #337ab7;
                background: white;
                color: #337ab7;
                padding: 5px 10px 6px 10px;
                font-size: 13px;

                &:hover {
                    color: white;
                    background-color: rgba(43, 101, 151, 0.72);
                    border: 1px solid #337ab7;
                    outline:none;
                    transition: background-color 0.1s linear;
                }

                &:focus {
                    outline:none;
                }

                i {
					vertical-align: middle;
					line-height: normal;

                	svg {
                    	display: inline;
                    	width: 18px;
                    	height: 18px;
                    }
                }

                span {
                    padding-left: 7px;
                }

                &.run-code span {
                    padding-left: 3px;
                }

                &.more-option {
                    padding: 0;
                    width: 18px;
                    min-width: 10px;
                    border: none;
                    align-items: center;
                    background: transparent;

                    &:hover {
                        color: white;
                        background-color: rgba(43, 101, 151, 0.72);
                        border: none;
                        outline:none;
                        transition: background-color 0.1s linear;
                    }
                }
            }

            .save-status {
                display: inline-flex;
                user-select: none;
                height: 30px;
                align-items: center;
                padding: 5px 10px;
                color: #555;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.2px;
            }
        }
    }

    .split-section {
        width: 100%;
        height: 100%;
        padding-left: 0;
    }

    .console-and-test-container {
        width: 100%;
        height: 100%;

        .test-container {
            width: 100%;
            height: 100%;
            color: #777;
            padding: 8px;
            font-size: 16px;
            overflow: auto;

            .list-group-item {
                .result-detail {
                    margin-top: 5px;
                    display: block;
                    overflow: auto;
                }

                &.list-group-item-success {
                    border: none;
                    padding: 10px 15px;
                    text-align: center;

                    .list-group-item-heading {
                        width: 110px;
                        background-color: #53a655;
                        color: white;
                        font-size: 15px;
                        height: 25px;
                        line-height: 25px;
                        margin-bottom: 0;
                    }
                }

                &.list-group-item-danger {
                    background-color: white;
                    border: 1px solid #a94442;

                    .list-group-item-heading {
                        width: 105px;
                        background-color: #a94442;
                        color: white;
                        font-size: 13px;
                        height: 20px;
                        line-height: 20px;
                    }

                    .result-detail {
                        &.input,
                        &.output,
                        &.expected,
                        &.fail-message {
                            .field {
                                font-size: 15px;
                            }

                            .value {
                                font-weight: bold;
                                letter-spacing: 0.3px;
                            }
                        }

                        &.input {
                            .field {
                                margin-right: 44px;
                            }

                            .value {
                                color: #555;
                            }
                        }

                        &.output {
                            .field {
                                margin-right: 33px;
                            }
                            .value {
                                color: #a94442;
                            }
                        }

                        &.expected {
                            .field {
                                margin-right: 17px;
                            }

                            .value {
                                color: rgb(22, 179, 21);
                            }
                        }

                        &.fail-message {
                            .field {
                                margin-right: 5px;
                            }

                            .value {
                                color: #a94442;
                                font-weight: normal;
                            }
                        }
                    }
                }

                .list-group-item-text {
                    text-align: left;
                }
            }
        }
    }

    .question-description {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: white;

        .solution-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 100px;
            height: 30px;
            border: 1px solid #337ab7;
            background: white;
            color: #337ab7;
            padding: 5px 5px 6px 5px;
            font-size: 13px;
            cursor: pointer;

            &:focus,
            &:focus:active {
                box-shadow: none;
            }

            &.disable-solution {
                cursor: auto;

                &:hover {
                    background: white;
                    color: #337ab7;
                }
            }

            &:hover {
                color: white;
                background-color: rgba(43, 101, 151, 0.72);
                border: 1px solid #337ab7;
                outline: none;
                transition: background-color 0.1s linear;
            }

            .book-icon {
                margin-right: 7px;
            }
        }

        .description-header {
            font-size: 17px;
            margin-bottom: 15px;

            .header-text {
                margin-right: 5px;
                color: #337ab7;
                font-weight: 600;
            }
        }

        .description {
            color: black;
            margin-bottom: 20px;

            .ql-container {
                font-size: 15px;
                border: none;

                .ql-editor {
                    padding: 0;
                }
            }
        }
    }

    .Resizer {
        background: $dividerColor;
        z-index: 1;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-background-clip: padding;
        -webkit-background-clip: padding;
        background-clip: padding-box;

        &:hover {
            -webkit-transition: all 2s ease;
            transition: all 2s ease;
        }

        &.horizontal {
            width: 100%;
            height: 11px;
            border-top: 5px solid $dividerColor;
            border-bottom: 6px solid $dividerColor;
            cursor: row-resize;

            &::after {
                content: " ";
                display: block;
                position: absolute;
                left: 50%;
                width: 29px;
                height: 1px;
                transform: translateX(-50%);
                background: #777;
            }
        }

        &.vertical {
            display: inline-flex;
            position: relative;
            width: 11px;
            height: 100%;
            padding-left: 5px;
            cursor: col-resize;
            align-items: center;

            &::after {
                content: " ";
                display: block;
                position: absolute;
                width: 1px;
                height: 29px;
                background: #777;
            }

            &:hover {
/*                border-left: 5px solid rgba(0, 0, 0, 0.5);
                border-right: 5px solid rgba(0, 0, 0, 0.5);*/
            }
        }

        &.disabled {
            cursor: not-allowed;

            &:hover {
                border-color: transparent;
            }
        }
    }

    .cookie-paragraph {
        font-size: 15px;
        margin-bottom: 5px;
    }

    @media only screen and (max-width: 600px) {
        .cookie-paragraph {
            font-size: 12px;
            margin-bottom: 3px;
        }
    }
}

.ReactCodeMirror {
    position: relative;
    width: 100%;
    height: 100%;

    .CodeMirror {
        font: $font;

        .CodeMirror-lines {
            padding-top: 0;
        }

        .CodeMirror-line,
        .CodeMirror-linenumber {
            line-height: 21px;
        }

        .CodeMirror-line {
            font-size: 16px;

            .cm-comment {
                color: rgb(76, 136, 107);
                 font-style: normal;
            }
        }

        .CodeMirror-selected,
        .CodeMirror-selectedtext {
            background-color: rgb(181, 213, 255);
        }

        .CodeMirror-linenumbers {
            width: 50px;
        }

        .CodeMirror-linenumber {
            height: 21px;
            font-size: 16px;
        }

        .CodeMirror-cursor {
            border-left: 2px solid black;
        }

        .CodeMirror-activeline-background {
            background-color: #ededed;
        }

        .CodeMirror-activeline-gutter {
            background-color: #dcdcdc;
        }

        .CodeMirror-focused .CodeMirror-selected {
            background: $codeMirrorSelected;
        }

        .CodeMirror-line::selection,
        .CodeMirror-line > span::selection,
        .CodeMirror-line > span > span::selection {
            background: $codeMirrorSelected;
        }

        .CodeMirror-line::-moz-selection,
        .CodeMirror-line > span::-moz-selection,
        .CodeMirror-line > span > span::-moz-selection {
            background: $codeMirrorSelected;
        }
    }
}

body .CodeMirror-hints {
    width: 240px;
    padding: 0;
    z-index: 200000;
    background: #fbfbfb;
    color: #444;
    border: 1px lightgray solid;
    position: fixed;
    box-shadow: 2px 3px 5px rgba(0,0,0,.2);
    line-height: 1.4;
    font: $font;
    font-size: 18px;
    line-height: 25px;

    .CodeMirror-hint {
        height: 25px;
        border-radius: 0;

        &:hover {
            background: #3379b724;
        }
    }

    .CodeMirror-hint-active {
        background: #3379b7;

        &:hover {
            background: #3379b7;
        }
    }
}

#solution-button-overlay {
    .arrow::before {
        border-bottom-color: #495057;
    }

    .tooltip-inner {
        background-color: #495057;
    }
}