$itemActiveBgColor: #e9e9e9;

.sideBarMenuContainer {
    position: absolute;
    width: 256px;
    height: 100%;
    top: 0;
    bottom: 0px;
    padding-top: 61px;
    padding-bottom: 48px;
    border-right: 1px solid #e7e7e7;

    .sidebar {
    	display: block;
        background-color: white;
        border: none;
        border-color: rgb(228, 228, 228);
        overflow: auto;
        padding: 0;
        user-select: none;

        .navbar-nav {
            margin: 7px 0;

            li {
                width: 237px;
                height: 100%;
                font-size: 14px;
                background-color: white;
                border-radius: 0 16px 16px 0;

                a {
                	position: relative;
    				display: block;
                    padding: 5px 20px;
    				line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-radius: 0 16px 16px 0;
                    cursor: pointer;
                }

                &.title {
                    font-size: 16px;

                    &.active,
                    &:hover {
                        background-color: $itemActiveBgColor;
                    }

                    &.active {
                        font-weight: 500;
                    }

                    &.active a {
                        background-color: $itemActiveBgColor;
                        border-left: 4px solid #337ab7;
                        color: #337ab7;

                        &:hover {
                            background-color: $itemActiveBgColor;
                        };
                    }

                    margin-top: 0;
                    color: black;

                    a {
                        color: black;
                        font-weight: 500;
                        padding: 8px 20px;
                    }
                }

                &.question:nth-child(2) .icon-container .upper-vertical {
                    border-color: transparent !important;
                }

                &.question:last-child .icon-container .lower-vertical {
                    border-color: transparent !important;
                }

                &.question {
                    height: 34px;

                    &.is-locked {
                        pointer-events: none;
                    }

                    &.active,
                    &:hover {
                        background-color: $itemActiveBgColor;
                    }

                    &.active {
                        font-weight: 600;
                        font-size: 16px;
                    }

                    &.active a {
                        color: #337ab7;

                        &:hover {
                            background-color: $itemActiveBgColor;
                        };
                    }

                    a {
                        height: 100%;
                        padding: 0 20px;
                        color: #58534d;
                    }

                    & .question-item {
                        height: 100%;

                        & .icon-container {
                            display: inline-block;
                            position: relative;
                            vertical-align: top;
                            width: 50px;
                            height: 100%;
                            /*border: 1px solid red;*/

                            & .upper-vertical,
                            & .lower-vertical {
                                position: absolute;
                                width: 24px;
                                height: 17px;
                                margin-left: 50%;
                                border-left: 1px solid #999;
                                z-index: 10;
                            }

                            & .lower-vertical {
                                top: 20px;
                            }

                            & .circle-icon-container {
                                position: absolute;
                                width: 13px;
                                height: 13px;
                                margin-left: 19px;
                                margin-top: 12px;
                                border: 1px solid black;
                                border-radius: 7px;
                                background: white;
                                z-index: 100;
                            }

                            &.pass {
                                & .circle-icon-container {
                                    border: 1px solid #41c463;
                                    background: #28a745;
                                }
                            }

                            &.fail {
                                & .circle-icon-container {
                                    border: 1px solid #dbab09;
                                    background: #dbab09;
                                }
                            }
                        }

                        & .question-title {
                            display: inline-block;
                            vertical-align: top;
                            width: 150px;
                            height: 100%;
                            text-overflow: ellipsis;
                            text-align: left;
                            line-height: 35px;
                            overflow: hidden;

                            .lock-icon {
                                color: #777;
                                top: -2px;
                                position: relative;
                                margin-right: 5px;
                            }
                        }
                    }
                }

                &.question-category {
                    width: 100%;
                    font-weight: 500;
                    font-size: 16px;

                    & a {
                        color: black;

                        .list-question-title {
                            display: inline-block;
                            width: 180px;
                            overflow: visible;
                            text-overflow: unset;
                            white-space: normal;
                            vertical-align: sub;
                        }

                        i {
                            position: relative;
                            display: inline-block;
                            width: 29px;
                            height: 29px;
                            vertical-align: top;
                            margin-left: 5px;
                            border-radius: 15px;
                            bottom: 0px;

                            &:hover {
                                background-color: #eee;
                                color: #337ab7;
                            }

                            svg {
                                position: relative;
                                top: 3px;
                                left: 5px;
                                width: 18px;
                                height: 18px;
                                -webkit-transition: 0.2s ease-in-out;
                                -moz-transition: 0.2s ease-in-out;
                                -o-transition: 0.2s ease-in-out;
                                transition: 0.2s ease-in-out;
                            }
                        }
                    }
                }
            }

            &.close-dropdown {
                max-height: 50px;
                transition: max-height 0.35s ease-out;

                .question-category {
                    i svg {
                        transform: rotate(-180deg);
                        top: 4px;
                    }
                }

                .question {
                    display: none;
                }
            }
        }
    }
}

.userMenu {
    width: 100%;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
}

.comment-image {
    display: none;
    // background: url('/comment.png') no-repeat center 6px;
}

.sidebar .navbar-brand .glyphicon {
    margin-right: 0px;
}

.sidebar.navbar {
    border-radius: 0px;
}

/*Center Brand*/
.sidebar.navbar.sidebar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
}
/*Center Brand*/
.sidebar .navbar-brand,
.sidebar .navbar-header {
    text-align: center;
    width: 100%;
    margin-left: 0px;
}

/*Center Icons*/
.sidebar a {
    padding-right: 13px;
}

/*adds border to bottom nav boxes*/
.sidebar .navbar-nav > li {
    /*border-bottom: 1px #e5e5e5 solid;*/
}

/* Colors/style dropdown box*/
.sidebar .navbar-nav .open .dropdown-menu {
    position: static;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*allows nav box to use 100% width*/
.sidebar .navbar-collapse,
.sidebar .container-fluid {
    padding: 0 0px 0 0px;
}

/*colors dropdown box text */
.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
}

/*gives sidebar width/height*/
.sidebar {
    width: 100%;
    height: 100%;
    z-index: 100;
    margin-bottom: 0px;
}

/*give sidebar 100% width;*/
.sidebar ul {
    width: 100%;

    li {
        width: 100%;
    }
}

.sidebar .navbar-nav .open .dropdown-menu > li > a:hover,
.sidebar .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #ccc;
    background-color: transparent;
}

nav:hover .forAnimate {
    opacity: 1;
}

section {
    padding-left: 15px;
}
