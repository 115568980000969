.header-container {
    position: relative;
    left: 0;
    right: 0;
    height: 60px;
    padding: 0 15px;
    line-height: 60px;
    border-bottom: 1px solid rgb(228, 228, 228);
    background-color: white;
    text-align: center;
    font-size: 14px;
    background: white;
    color: #4b4f56;
    box-shadow: 0 0 1px rgba(0,0,0,.14);
    overflow: hidden;
    z-index: 100;

    .right-panel {
        position: relative;
        height: 100%;
        float: right;

        .sign-up-container,
        .log-in-container,
        .about-app-container,
        .premium-container {
            position: relative;
            height: 100%;
            padding: 0 10px;
            float: right;
            line-height: 61px;
            border-bottom: none;

            &:hover:after {
                transform: scaleX(1);
                transition: transform 250ms ease-in-out;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                color: #39464E;
                text-decoration: none;
            }

            &:after {
                display:block;
                content: '';
                position:absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                border-bottom: solid 2px #337ab7;
                transform: scaleX(0);  
                transition: transform 50ms ease-in-out;
            }
        }
    }

    .nav {
        position: relative;
        height: 100%;
        float: right;

        li {
            height: 100%;

            a {
                height: 100%;
                padding: 0 14px 0 16px;
                transition: border .4s ease;
                border-top: 4px solid rgba(255,255,255,0);

                &:hover {
                    border-top: 4px solid #1B70E0;
                    text-decoration: none;
                    background-color: white;

                    .glyphicon-log-out {
                        color: #1B70E0;
                    }
                }

                .glyphicon-log-out {
                    top: -5px;
                    color: #bdbdbd;
                    font-size: 18px;
                    transition: color .4s ease;
                }
            }
        }
    }

	.brand-container {
	    position: relative;
	    width: 60px;
	    height: 100%;
	    float: left;

	    &:hover {
	        cursor: pointer;
	    }

	    .logo {
	        width: 50px;
	        height: 100%;
	        background: url("/js_crunch_logo_small.png") no-repeat center 12px;
	        background-size: 37px;
	    }

	    .name {
	        position: absolute;
	        top: 0;
	        height: 100%;
	        padding-left: 53px;
	        color: #4b4f56;

	        h1 {
	            height: 100%;
	            padding: 0;
	            margin: 0;
	            line-height: 60px;
	            font-size: 16px;
	            letter-spacing: 1px;
	            color: gray;
	            word-spacing: 1px;
	        }
	    }
	}
}
