.console {
  width: 100%;
  height: 100%;
  position: relative;

  pre {
    background-color: #282822;
    border-radius: 0;
    border: none;
  }
}

/* The inner console element. */
.jqconsole {
    padding: 10px;
}
/* The cursor. */
.jqconsole-cursor {
    background-color: gray;
}
/* The cursor color when the console looses focus. */
.jqconsole-blurred .jqconsole-cursor {
    background-color: #666;
}
/* The current prompt text color */
.jqconsole-prompt {
    color: #0d0;
}
/* The command history */
.jqconsole-old-prompt {
    color: #0b0;
    font-weight: normal;
}
/* The text color when in input mode. */
.jqconsole-input {
    color: #dd0;
}
/* Previously entered input. */
.jqconsole-old-input {
    color: #bb0;
    font-weight: normal;
}
/* The text color of the output. */
.jqconsole-output {
    color: white;
}

.jqconsole-last-return-result {
    color: white;
}

.jqconsole-header {
    color: white;
    display: block;
}
