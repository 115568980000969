.solution-modal {
	.modal-title {
		font-weight: normal;
		font-size: 16px;
		color: #333;
	}

	.modal-header {
		button {
	    	position: relative;
    		top: -1px;

    		&:focus,
    		&:active {
    			border: none;
    		}
    	}

		.close {
			opacity: 0.6;
		}
	}
}