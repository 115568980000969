.account-container {
	position: relative;
	width: 100%;
	height: 100%;

	.message-container {
    	color: #2a8d2b;
    	font-size: 16px;

    	&.error {
       		color: #f10c07;
    	}
	}

	.left-bar {
		position: relative;
	    width: 230px;
		height: 100%;
		padding: 20px;

		.vdivider-container {
			position: absolute;
			top: 0;
			right: 0;
			width: 1px;
			height: 100%;
			padding: 20px 0;

			.vdivider {
				border-right: 1px solid #eee;
				height: 100%;
				-webkit-box-shadow: 1px 1px 1px 1px #f0f0f036;
				-moz-box-shadow: 1px 1px 1px 1px #f0f0f036;
				box-shadow: 1px 1px 1px 1px #f0f0f036;
				/*update box shadow everyway*/
			}
		}
	}

	.account-form-wrapper {
	    position: absolute;
		top: 0;
		width: 100%;
		min-width: 800px;
		height: 100%;
		padding-left: 230px;

		.profile-form-container {
			position: relative;
			width: 100%;
			height: 100%;
			padding-top: 100px;

			.profile-form {
	        	position: absolute;
	        	left: 50%;
	        	width: 500px;
	        	margin: 0 auto;
	        	-webkit-transform: translateX(-50%);
	        	transform: translateX(-50%);

	        	.change-password {
	        		margin-top: 80px;
	        	}
	        }
	    }
	}

	.side-nav {
		margin-top: 10px;
		margin-left: 50px;
		font-size: 16px;
		font-weight: 600;

		ul {
			list-style: none;
    		padding-left: 0;
    	}
	}
}
