$input-height: 50px;
$red: #F16767;
$green: #50b87f;

.input_group {
  position: relative;
  width: 100%;
  min-height: 55px;
  margin-bottom: 10px;

  label.input_label {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    cursor: text;
    padding: 0 0 0 15px;

    .label_text {
      position: absolute;
      top: 15px;
      font-size: 15px;
      font-weight: 100;
      letter-spacing: .7px;
      /*-webkit-transition: all 0.15s linear;
      -moz-transition: all 0.15s linear;
      -o-transition: all 0.15s linear;
      transition: all 0.15s linear;*/
      color: #333;
      font-weight: 200;
    }
  }

  &.input_empty.input_unfocused {
    color: #c1c5cc;
  }

  /*&.input_focused, &.input_hasValue {*/
  label.input_label {
    height: 20px;

    .label_text {
      top: 4px;
      font-size: 13px;
    }
  }
  /*}*/

  &.input_unfocused.input_hasValue.input_valid {
    label.input_label {
      color: #b1b0b1;
    }
  }

  input.input {
    position: relative;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    transition: all, 0.7s, ease-in-out;
    z-index: 1;
    -webkit-appearance: none;
    outline: none;
    padding: 24px 14px 8px;
    margin: 0 auto 13px auto;
    color: #363b4a;
    letter-spacing: .7px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    height: 50px;
    border: 1px solid #cccccc;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      color: #cacaca;
    }
  }

  i {
  	top: 0;
  	right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20px;
    height: $input-height;
    z-index: 1000;
    position: absolute;
    z-index: 100;

    svg {
      opacity: 0;
      transition: all, 0.2s, ease-in-out;
      width: 20px;
      height: 20px;
    }

    &.input_error_icon {
      z-index: 10;
      path {
        fill: $red;
      }
    }   

    &.input_valid_icon {
      z-index: 1;
      path {
        fill: $green;
      }
    }
  }

  &.input_error  {
    i {
      &.input_error_icon {
        svg {
          opacity: 1
        }
      }
    }  
  }

  &.input_valid {
    i {
      &.input_error_icon {
        z-index: 1;
      }
      &.input_valid_icon {
        z-index: 10;
      }
    }

    &.input_hasValue {

      i {
        &.input_valid_icon {
          svg {
            opacity: 1
          }
        }  
      }
    }
  } 
}
