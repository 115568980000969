/*Buttons*/
$button-default-color: #fff;
$button-default-bg: #545a6a;
/*Main colors*/
$light-blue: #40b4de;
$light-dark-blue: #286090;

.button {
  transition: all, 0.2s, ease-in-out;
  user-select: none;
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 3px;
  background: $button-default-bg;
  color: $button-default-color;
  font: normal normal 200 13px/40px 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  height: 40px;
  padding: 0px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: pointer;
  white-space: nowrap;

  &.button_wide {
    position: relative;
    width: 100%;
    margin: 20px 0 0 0;
    height: 50px;
    font-size: 13px;
  }

  &:hover,
  &:focus {
    color: $button-default-color;
    background: $light-dark-blue;
  }

  &:active,
  &.active {
    box-shadow: none;
    background: lighten($light-blue, 10%);
  }
}
