.brand-container {
    position: relative;
    width: 60px;
    height: 100%;
    float: left;

    &:hover {
        cursor: pointer;
    }

    .logo {
        width: 50px;
        height: 100%;
        background: url("/js_crunch_logo_small.png") no-repeat center 12px;
        background-size: 37px;
    }

    .name {
        position: absolute;
        top: 0;
        height: 100%;
        padding-left: 53px;
        color: #4b4f56;

        h1 {
            height: 100%;
            padding: 0;
            margin: 0;
            line-height: 60px;
            font-size: 16px;
            letter-spacing: 1px;
            color: gray;
            word-spacing: 1px;
        }
    }
}
