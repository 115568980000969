.privacyAndTermContainer {
    bottom: 0;
    width: 256px;
    height: 48px;
    z-index: 100;
    text-align: left;
    border-top: 1px solid #e7e7e7;
    font-size: 13px;
    padding: 15px;
    font-weight: 500;

    .link {
        font-weight: normal;
    	text-decoration: underline;
    	color: #555;
    }
}

.privacy-container {
    height: 100%;

    .privacy-main-container {
        width: 800px;
    }

    @media only screen and (max-width: 850px) {
        .privacy-main-container {
            width: 600px;
        }
    }

    @media only screen and (max-width: 650px) {
        .privacy-main-container {
            width: 100%;
        }

        .title-container {
            h1 {
                text-align: center;
            }

            h2 {
                font-size: 22px !important;
                text-align: center;
            }
        }
    }
}

.term-container {
    height: 100%;

    .term-main-container {
        width: 800px;
    }

    @media only screen and (max-width: 850px) {
        .term-main-container {
            width: 600px;
        }
    }

    @media only screen and (max-width: 650px) {
        .term-main-container {
            width: 100%;
        }

        .title-container {
            h1 {
                text-align: center;
            }

            h2 {
                font-size: 22px !important;
                text-align: center;
            }
        }
    }
}
