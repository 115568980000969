.profile-container {
    float: right;
    position: relative;
    height: 100%;
    padding: 0 5px;
    overflow: hidden;
    cursor: pointer;

    &:after {
        display:block;
        content: '';
        position:absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        border-bottom: solid 2px #337ab7;
        transform: scaleX(0);  
        transition: transform 50ms ease-in-out;
    };

    &:hover {
        .profile-button .profile-image {
            margin-top: 1px;
        }

        &:after {
            transform: scaleX(1);
            transition: transform 250ms ease-in-out;
        }
    }

    .profile-button {
        display: inline-block;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        background-color: #3fbbbf;
        border-radius: 50%;

        .username {
            line-height: 33px;
            color: white;
            vertical-align: top;
            font-size: 18px;

            -webkit-user-select: none;  /* Chrome all / Safari all */
            -moz-user-select: none;     /* Firefox all */
            -ms-user-select: none;      /* IE 10+ */
            user-select: none;          /* Likely future */
        }
    }

    .setting-dropdown {
        display: none;
        position: fixed;
        top: 108px;
        right: 1px;
        cursor: initial;
        z-index: 100;

        ul.dropdown-menu {
            right: 0;
            left: auto;
            top: -50px;
            padding-top: 12px;
            padding-bottom: 9px;
            z-index: 0;
            display: block;
            background-color: white;
            border-color: #dfdfdf;
            -webkit-box-shadow: 0 2px 8px rgba(0,0,0,.175);
            box-shadow: 0 2px 8px rgba(0,0,0,.175);
            line-height: 1.5;
            white-space: nowrap;

            .dropdown-header {
                text-overflow: ellipsis;
                max-width: 158px;
                overflow: hidden;
                color: #333;
                font-size: 15px;
                padding: 0 20px;

                .upper {
                    padding-bottom: 2px;
                }

                strong {
                    font-weight: 600;
                }
            }

            .divider {
                height: 1px;
                margin: 9px 0;
                overflow: hidden;
                background-color: #e5e5e5;
            }

            li a {
                display: block;
                padding: 3px 20px;
                clear: both;
                font-weight: 400;
                line-height: 1.42857143;
                color: #333;
                white-space: nowrap;
                cursor: pointer;
                font-size: 14px;

                &:hover {
                    background-color: #ededed;
                }
            }

        }

        &:after {
            bottom: 46px !important;
            border-bottom-color: white !important;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 48px;
            left: calc(50% - 46px);
            border: 10px solid transparent;
            border-bottom-color: #dfdfdf;
        }
    }
}
