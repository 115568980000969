.password_validator {
  top: 0;
  left: 105%;
  position: absolute;
  display: block;
  opacity: 0;
  transition: all, 0.2s, ease-in-out;
  width: 320px;
  height: auto;
  box-sizing: border-box;
  user-select: none;
  transition: all, 0.2s, ease-in-out;
  opacity: 1;
  z-index: 100;


  &:after {
    position: absolute;
    top: 30px;
    left: -10px;
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #e4e7e8;
  }

  .validator_container {
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    background: #e4e7e8;
    padding: 10px 0px 10px 20px;

    h4.validator_title {
      position: relative;
      transition: all, 0.2s, ease-in-out;
      margin: 12px 0 5px 0;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: black;
      opacity: 0.5;
      letter-spacing: 1.5px;

      &.valid {
        color: black;
        opacity: 1
      }
    }

    ul.rules_list {
      position: relative;
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        position: relative;
        margin: 12px 0 12px 0;
        font-size: 12px;
        letter-spacing: .3px;
        font-weight: 200;
        color: black;

        .bad_word {
          font-style: italic;
          padding: 0 5px 0 0;

          &:after {
            content: ',';
            display: inline;
          }

          &:last-child {
            padding: 0;

            &:after {
              display: none;
            }
          }
        }

        .icon_invalid {
          position: absolute;
          top: 1px;
          left: 0;
          scale: 1;
          opacity: 1;
          width: 18px;
          height: 18px;
          transition: all, 0.2s, ease-in-out;

          svg {
            opacity: 1;
            width: 18px;
            height: 18px;

            path {
              fill: #F16767;
            }
          }
        }

        .icon_valid {
          position: absolute;
          top: 1px;
          left: 0;
          scale: 0;
          opacity: 0;
          width: 19px;
          height: 19px;
          transition: all, 0.2s, ease-in-out;

          svg {
            opacity: 1;
            width: 19px;
            heigh: 19px;
          }
        }

        .error_message {
          position: relative;
          transition: all, 0.2s, ease-in-out;
          padding: 0 0 0 27px;
          opacity: .9;
        }

        &.valid {
          .icon_invalid {
            scale: 0;
            opacity: 0;
          }

          .icon_valid {
            scale: 1;
            opacity: 1;
          }
          
          .error_message {
            opacity: .3;
          }

        }
      }
    }
  }

  &.visible {
    transform: translateX(0px);
    opacity: 1;
  }

  &.invisible {
    transform: translateX(-15px);
    opacity: 0;
    -webkit-animation-delay: 2s; /* Chrome, Safari, Opera */
    animation-delay: 2s;
  }
}
